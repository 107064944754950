//Beta API
let config = {
  // Live Server
  apiBaseUrl: "https://otobix.in/admin/api/",
  assetsBaseUrl: "https://otobix.in/admin/public/",
  sitePortalBaseUrl: "https://otobix.in",
  // apiBaseUrl1: "https://integration.automovill.com/integration",
};

export default Object.freeze(Object.assign({}, config));
