import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import successLogo from "../../assets/images/success.png";

function ThankYou({ isShowLoader }) {
  isShowLoader(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { responseData } = location.state || {};


  //change route function
  let changeRoute = (e, path) => {
    e.preventDefault();
    if (path !== null && path !== "") {
      navigate(path);
    }
  };

  return (
    <div>
      <div class="container">
        <div class="thankyou-box">
          <h2 class="animationActive">Thank You!</h2>
          <span class="chkrund">
            <img src={successLogo} />
          </span>
          <p>
            Your enquiry has been submitted successfully
            {responseData?.flag && responseData.flag === true
              ? ` with Reference Id `
              : "."}
            {responseData?.flag && responseData.flag === true && (
              <span style={{ fontWeight: "bold", color: "blue" }}>
                {responseData.message}
              </span>
            )}
            {responseData?.flag && responseData.flag === true && (
              <span>. We will get back to you soon.</span>
            )}
          </p>
          {responseData?.flag && responseData.flag === true && (
            <p style={{ fontWeight: "bold" }}>
             <span style={{ color:"red" }}>*</span> Please save your Reference Id for checking your booking status
              later.
            </p>
          )}
          <div class="thkubtn-sec">
            <a class="btnbck" href="" onClick={(e) => changeRoute(e, "/")}>
              Go Back
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
